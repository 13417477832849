<template>
  <div>
    <slot
      :available-language="unSelectedLanguage"
      :selected-language="selectedLanguage"
    ></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, markRaw } from "vue";
import iconFlagUK from "~icons/hh-icons/icon-flag-uk";
import iconFlagTH from "~icons/hh-icons/icon-flag-th";

const languageOptions = [
  {
    lang: "en",
    icon: markRaw(iconFlagUK),
  },
  {
    lang: "th",
    icon: markRaw(iconFlagTH),
  },
];

const props = defineProps<{
  lang: string;
}>();

const selectedLanguage = computed(() => {
  const filtered = languageOptions.filter((lang) => lang.lang === props.lang);
  return filtered.length ? filtered[0] : languageOptions[1];
});

const unSelectedLanguage = computed(() => {
  return languageOptions.filter((lang) => lang.lang !== props.lang);
});
</script>
